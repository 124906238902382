import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"
import imgDigitalWalkaround from "../../images/news/digital-walkaround-checks.jpg"
import imgDigitalMaintenance from "../../images/news/digital-periodic-maintenance.jpg"
import imgOcrsCalc from "../../images/news/ocrs-calculation.png"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Commitment to safety" description="At Tetley's Motor Services, safety is our top priority. Through a combination of thorough safety inspections and advanced digital technology, we ensure that every vehicle in our fleet is roadworthy and safe for service. Find out more about our commitment to safety." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Commitment to safety</h1>
              <p className="news-article-date">September 2024</p>
            </div>

            <div className="news-article-body">
              <h3 style={{ marginBottom: 10 }}>Maintaining High Standards in Fleet Inspections and Compliance</h3>
              <p>At Tetley's Motor Services, safety is our top priority. Through a combination of thorough safety inspections and advanced digital technology, we ensure that every vehicle in our fleet is roadworthy and safe for service.</p>
              <img src={imgDigitalWalkaround} alt="" />
              <h3 style={{ marginBottom: 10 }}>Thorough Safety Inspections</h3>
              <p>Each day, our drivers perform detailed walk-around checks of their vehicles, logging the results on a dedicated driver app. Any issues or defects are immediately flagged to both the transport office and our in-house maintenance team, allowing for swift action before a vehicle goes into service. This seamless communication ensures that no vehicle is used unless it meets our strict safety standards.</p>
              <img src={imgDigitalMaintenance} alt="" />
              <h3 style={{ marginBottom: 10 }}>Six-Weekly Periodic Maintenance Inspections</h3>
              <p>In addition to daily checks, our vehicles undergo six-weekly maintenance inspections by our qualified maintenance staff. These inspections are logged on a tablet, with any necessary repairs instantly communicated to the transport office. This data is integrated into our scheduling system, ensuring that only vehicles deemed safe are allocated for service. If any issues arise, the system automatically removes the vehicle from the schedule until it’s fully roadworthy.</p>
              <img src={imgOcrsCalc} alt="" />
              <h3 style={{ marginBottom: 10 }}>Our OCRS Score: Safety You Can Trust</h3>
              <p>We proudly maintain a "Green – Low Risk" rating with the DVSA’s Operators Compliance Risk Score (OCRS) system. Our use of digital technology enhances communication between drivers, maintenance staff, and schedulers, strengthening our safety protocols. This integrated approach allows us to react quickly to any potential issues and maintain the highest standards of vehicle safety and compliance.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
